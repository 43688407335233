import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'


import v1 from '../../assets/images/solvercs/solver1.webp'
import v3 from '../../assets/images/solvercs/solver2.png'
import v4 from '../../assets/images/solvercs/solver.webp'

import icon from '../../assets/images/solvercs/icon-114.png'


class SolverPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet title="SolverCS - Free crossword solver app | Asgaard Software" />
                <HeaderGeneric title="SolverCS" subtitle="SolverCS - A free crossword solver app" showHome="true" />
                <div id="main" class='solver-page page'>
                    <section id="content" className="main solver">
                        <h2> <img src={icon} /> SolverCS </h2>
                        <p> SolverCS is a free crossword solver app for Android. </p>
                        <div class='images app-promo'>
                            <img src={v1} alt="Record your thoughts, ideas and meetings" class='double' />
                            <img src={v3} alt="Enjoy a modern, dark mode user interface" />
                            <img src={v4} alt="Listen to, manage and share your recordings" />
                        </div>

                        <p> SolverCS is a <a href='https://solvercs.asgaard.co.uk/'>free crossword and anagram solver app</a> for Android. SolverCS features a crossword and anagram solver, identifying missing letters for you, 
                            with an internal dictionary of around 350,000 English words. As well as this, SolverCS includes definition lookup for words you might not be familiar with,
                            and links to Wiktionary.
                        </p>

                        <p>
                            SolverCS features a simple and focused user interface, with no distractions, nag screens or paywalls.
                        </p>

                        <p>SolverCS currently has over 5,000 downloads on the Google Play store. </p>
                        <p>We are currently in the process of updating SolverCS for a more modern look and feel, and improved performance.</p>


                        <div class='play-badge'>
                            <a href='https://play.google.com/store/apps/details?id=com.markw.solver_free&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </div>


                        <h2> History of Crosswords </h2>
                        <p> Crosswords appeared in various publications, mostly aimed at children, in the 19th century.
                            In the early 1900s, newspapers across the United States began publishing crossword puzzles for adults, and became a craze across the North Atlantic.
                        </p>
                        <p> Not all attention was positive, however, and the New York Times lamented crosswords as a "sinful waste" and "primitive mental exercise", before going
                            on to publish one of the most respected crossword puzzles in the world.
                        </p>
                        <p> In 1944 shortly before D-Day, a controversy erupted in England whereby security services believed that military plans for the upcoming invasion of 
                            mainland Europe were being leaked through crosswords in The Daily Telegraph. The crosswords had been compiled by Leonard Dawe, who served as headmaster
                            of a school near to a camp of US and Canadian soldiers. Dawe was arrested and interrogated by MI5, but it was revealed that Dawe had enlisted
                            the help of boys from his school to suggest words for his crosswords, which had presumably been influenced by their contact with the nearby soldiers.
                        </p>
                        <p>Today, crosswords are enjoyed by many people worldwide, but are especially popular in the UK and Australia.</p>


                    </section>
                </div>
            </Layout>
        )
    }
}

export default SolverPage